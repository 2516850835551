// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  demo: false,
  bluectrlapi: 'https://staging.api.bluectrl.app',
  ModuleWifiSurFix: 'BLUELINE_',
  DefaultModuleIP: '192.168.1.10',
  DefaultModuleApi: 'https://192.168.1.10:8443/',
  DefaultModuleWifiKey: 'BLUELINE_FTW',
  ClusterWifiSurFix: 'BLUECTRL_',
  DefaultClusterWifiKey: 'SdHnRPCM8962c3JY',
  GrafanaClusterLogUrl: 'https://grafana.esenseial.at/d/Sf1ZRVS4z/bluectrl-cluster-logging?orgId=11&var-clusterid='
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
